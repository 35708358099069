import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import SearchUser from "./pages/Search/Search.js";
import Profile from "./pages/Profile/Profile.js";
import "../src/assets/styles/Index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AnimatePresence mode="wait">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SearchUser />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </BrowserRouter>
  </AnimatePresence>
);

reportWebVitals();
