import Swal from "sweetalert2";
import axios from 'axios';
import { config } from "../config.js";

// Tạo một instance của axios với cấu hình cơ bản
const API = axios.create({
  baseURL: config.BE_API_URL, // Thay đổi baseURL nếu cần
  headers: {
    'Content-Type': 'application/json'
  }
});

// Thêm interceptor để xử lý trước khi request và sau khi response
API.interceptors.request.use(
  config => {
    // Có thể thêm các header ở đây nếu cần, ví dụ: token
    // config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    // Xử lý lỗi api chung
    if (error?.response?.data) {
      Swal.fire({
        icon: "error",
        title: error?.response?.data?.error,
        text: error?.response?.data?.message,
      });
      return error?.response;
    }
    Swal.fire({ icon: "error", text: "Thao tác lỗi, vui lòng thử lại." });
    return false;
    // if (error.response && error.response.status === 401) {
    //   window.location.href = '/login';
    // }
    // return Promise.reject(error);
  }
);

// Các hàm gọi API
const get = (url, params) => {
  return API.get(url, { params });
};

const post = (url, data) => {
  return API.post(url, data);
};

const put = (url, data) => {
  return API.put(url, data);
};

const remove = (url) => {
  return API.delete(url);
};

export { get, post, put, remove };
