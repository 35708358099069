import React, { useEffect, useState } from 'react';
import "../../assets/styles/Search.css";
import Swal from "sweetalert2";
import iconSearch from "../../icons/icon-search.png";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { config } from "../../config.js";
import { apiCheckAccount, apiGetSetting } from "../../services/api.service.js";

function SearchUser() {
  const [userName, setUserName] = useState("");
  const [domains, setDomains] = useState("");
  const [webSetting, setWebSetting] = useState("");
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        let result = await apiGetSetting();
        if (result && result.status_code === 200) {
          setWebSetting(result.data);
        }
      } catch (error) {
        return Swal.fire({ icon: "error", text: "Lỗi kết nối, vui lòng liên hệ CSKH để được hỗ trợ." });
      }
    };
    fetchData();
  }, []);

  const checkUser = async () => {
      if (!userName) {
        return Swal.fire({ icon: "error", text: "Vui lòng nhập tên tài khoản." });
      } else {
        let result = await apiCheckAccount(userName);
        if (result && result?.code === 200) {
          let data = result.data;
          navigate("/profile",{ state: { userName, data, webSetting } });
        }
      }
  };

  return (
    <div className="container">
      <div className="app">
        <Header isPageSearch={true} />
        <div className="content">
          <div className="search-user">
            <span>VUI LÒNG XÁC NHẬN TÀI KHOẢN CỦA BẠN</span>
            <div className="input-search">
              <img className="icon-search" alt="icon-search" src={iconSearch} />
              <input className="input-account"
                onChange={(e) => setUserName(e.target.value.toLowerCase())}
                type="text"
                placeholder="Nhập tên đăng nhập"
              />
              <button className="btn-search-account" onClick={checkUser}>KIỂM TRA</button>
            </div>
            <div className="description" dangerouslySetInnerHTML={{ __html: webSetting.login_info_notice }} >
            </div>
            {/* <div className="description">
              <p>
              <span style={{ color: "green" }}> Link chính thức: </span><span style={{ color: "red" }}> { domains || '789b1.com 789b3.com' }</span><br />
                - 789BET không yêu cầu Hội viên điền thông tin, số điện thoại
                khi đăng nhập. Nhân viên chính thức của 789BET sẽ không yêu cầu
                thành viên đăng ký lại tài khoản ở bất kỳ trang web lạ nào và
                với bất kỳ lý do nào. Nếu quý Hội viên truy cập thông qua đường
                link lạ hãy lập tức thay đổi mật khẩu đăng nhập tránh bị kẻ xấu
                ăn cắp...
                <br /> - Vui lòng sử dụng email của quý Hội viên gửi tin nhắn
                yêu cầu vào email chính thức của 789BET:
                <span style={{ color: "red" }}> admin@789bet.com</span> để nhận
                được link truy cập mới nhất.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchUser;
