import React, { useEffect } from 'react';
import "../../assets/styles/Profile.css";
import { useState } from "react";
import Popup from "reactjs-popup";
import { motion } from "framer-motion";
import Header from "../../components/Header.js";
import InfoUser from "../../components/Profile/InfoUser.js";
import DataConfirm from "../../components/Profile/DataConfirm.js";
import iconUser from "../../icons/icon-user.png";
import iconPhone from "../../icons/icon-phone.png";
import iconMail from "../../icons/icon-mail.png";
import iconBank from "../../icons/icon-bank.png";
import Collapsible from "react-collapsible";
import iconClose from "../../icons/icon-close.png";
import iconSendMail from "../../icons/icon-send-mail.png";
import iconSendPhone from "../../icons/icon-send-phone.png";
import iconWarning from "../../icons/icon-warning.png";
import iconSuccess from "../../icons/icon-success.png";
import iconArrow from "../../icons/icon-arrow.png";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { config } from "../../config.js";
import { apiConfirmUpdate, apiUpdateAccount, apiCheckDataAccount } from "../../services/api.service.js";

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


function Profile() {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [checkConfirm, setCheckConfirm] = useState(false);
  const [typeModal, setTypeModal] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openResult, setOpenResult] = useState(false);
  const [isSendMail, setIsSendMail] = useState(true);
  const { userName, data, webSetting } = location.state || {};
  const [linkCSKH, setlinkCSKH] = useState("");
  const [timesCheckData, setTimesCheckData] = useState(0);
  const [dataCheck, setDataCheck] = useState({
    name: "",
    province: "",
    bank_name: "",
    bank_number: "",
    email: "",
    phone_number: "",
  });
  const originalDataUpdate = {
    name: "",
    province: "",
    bank_name: "",
    bank_number: "",
    province: "",
    email: "",
    phone_number: "",
  };
  const [dataUpdate, setDataUpdate] = useState(originalDataUpdate);
  const [user, setUser] = useState({
    account: userName,
    update_type: "",
    status: "",
    auto_check: "",
    send_type: "",
  });
  const [errors, setErrors] = useState({});
  let contentUpdate;
  const navigate = useNavigate();

  const schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Email is invalid').required('Email is required'),
    phoneNumber: yup.string().matches(/^[0-9]+$/, 'Phone number must be digits').required('Phone number is required'),
  });

  const shouldNavigate =(!userName || userName === "undefined");
  useEffect(() => {
    if (shouldNavigate) {
      navigate('/');
    }
  }, [navigate, shouldNavigate]);

  if (!shouldNavigate) {
    const updateUserField = (field, value) => {
      setUser((prevUser) => ({
        ...prevUser,
        [field]: value,
      }));
    };

    const inputDataCheck = (event) => {
      const { name, value } = event.target;
      setDataCheck({
        ...dataCheck,
        [name]: value,
      });
    };

    const inputDataUpdate = (event) => {
      const { name, value } = event.target;
      setDataUpdate({
        ...dataUpdate,
        [name]: value,
      });
    };

    const openModal = (type) => {
      if (type === "FULL_NAME") {
        updateUserField("update_type", "full_name")
        setTypeModal("FULL_NAME");
      } else if (type === "PHONE_NUMBER") {
        updateUserField("update_type", "phone_number")
        setTypeModal("PHONE_NUMBER");
      } else if (type === "MAIL") {
        updateUserField("update_type", "email")
        setTypeModal("MAIL");
      } else if (type === "BANK") {
        updateUserField("update_type", "bank")
        setTypeModal("BANK");
      }
      document.body.style.overflow = 'hidden';
      setOpen(true);
    };

    const openModalConfirm = (type) => {
      setOpenConfirm(true);
      document.getElementsByClassName("popup-overlay")[0].style.visibility = "hidden";    
    };

    const validateForm = () => {
      let errors = {};
      let formIsValid = true;
  
      // Kiểm tra trường Name
      if (!dataCheck.name) {
        formIsValid = false;
        errors.name = 'Vui lòng nhập Họ và Tên';
      }
  
      // Kiểm tra trường phone_number
      if (data.phone_number) {
        if (!dataCheck.phone_number) {
          formIsValid = false;
          errors.phone_number = 'Vui lòng nhập Số Điện Thoại';
        } else if (!/^\d{10}$/.test(dataCheck.phone_number)) {
          formIsValid = false;
          errors.phone_number = 'Số Điện Thoại không hợp lệ';
        }
      }
  
      // Kiểm tra trường email
      if (data.email) {
        if (!dataCheck.email) {
          formIsValid = false;
          errors.email = 'Vui lòng nhập Email';
        } else if (!/\S+@\S+\.\S+/.test(dataCheck.email)) {
          formIsValid = false;
          errors.email = 'Email không hợp lệ';
        }
      }
  
      // Kiểm tra trường bank_name
      if (!dataCheck.bank_name) {
        formIsValid = false;
        errors.bank_name = 'Vui lòng nhập Ngân Hàng';
      }
  
      // Kiểm tra trường bank_number
      if (!dataCheck.bank_number) {
        formIsValid = false;
        errors.bank_number = 'Vui lòng nhập Số Tài Khoản';
      }
      switch (typeModal) {
        case "FULL_NAME": // Kiểm tra trường Name update
        if (!dataUpdate.name) {
          formIsValid = false;
          errors.nameUpdate = 'Vui lòng nhập Họ và Tên mới';
        } else {
          contentUpdate = dataUpdate.name;
        }
        break;
        case "PHONE_NUMBER":// Kiểm tra trường phone_number
          if (!dataUpdate.phone_number) {
            formIsValid = false;
            errors.phoneNumberUpdate = 'Vui lòng nhập Số Điện Thoại mới';
          } else if (!/^\d{10}$/.test(dataUpdate.phone_number)) {
            formIsValid = false;
            errors.phoneNumberUpdate = 'Số Điện Thoại mới không hợp lệ';
          } else {
            contentUpdate = dataUpdate.phone_number;
          }
          break;
        case "MAIL":// Kiểm tra trường email
          if (!dataUpdate.email) {
            formIsValid = false;
            errors.emailUpdate = 'Vui lòng nhập Email mới';
          } else if (!/\S+@\S+\.\S+/.test(dataUpdate.email)) {
            formIsValid = false;
            errors.emailUpdate = 'Email mới không hợp lệ';
          } else {
            contentUpdate = dataUpdate.email;
          }
          break;
        case "BANK":// Kiểm tra value bank
          if (!dataUpdate.bank_name) {
            formIsValid = false;
            errors.bankNameUpdate = 'Vui lòng nhập Ngân Hàng mới';
          }
          if (!dataUpdate.bank_number) {
            formIsValid = false;
            errors.bankNumberUpdate = 'Vui lòng nhập Số Tài Khoản mới';
          } else {
            contentUpdate = (dataUpdate.bank_number + " - " + dataUpdate.bank_name + " - " + dataUpdate.province)
          }
          if (!dataUpdate.province) {
            formIsValid = false;
            errors.province = 'Vui lòng nhập chi nhánh Ngân Hàng mới';
          }
          break;
        default: break
      }
      console.log(errors)
      setErrors(errors);
      return formIsValid;
    };
    
    const updateUser = async (type) => {
      if (validateForm()) {
        if (type === "MAIL") {
          user.send_type = 'email';
          setIsSendMail(true);
        } else if (type === "PHONE_NUMBER") {
          user.send_type = 'phone_number';
          setIsSendMail(false);
        }
        let body = {
          ...user,
          data_check: {
            ...dataCheck,
          },
          data_update: {
            update_type: user.update_type,
            content: contentUpdate,
          }
        };
        let checkDataOld = await apiCheckDataAccount(body);
        if (checkDataOld) {
          let confirmUpdate = checkDataOld.result;
          let value_auto_check = confirmUpdate ? "check_success" : "check_fail";
          updateUserField("auto_check", value_auto_check);
          body.auto_check = value_auto_check;
          //nếu thông tin đúng hết hoặc đã nhập lại từ 5 lần và có số điện thoại hoặc email đúng thì tự động confirm gửi yêu cầu
          if (confirmUpdate || (timesCheckData >= 100 && checkDataOld.allowSubmit >0)) {
            confirmUpdateUser(body, type);
          } else {
            Swal.fire({
              icon: (checkDataOld.countMatch/checkDataOld.count <0.5) ? "error" : "warning",
              title: "Chú ý",
              text: (checkDataOld.allowSubmit == 0) ? "Sai số điện thoại và email liên kết" : "Thông tin cũ bạn nhập đúng " + Math.round(checkDataOld.countMatch/checkDataOld.count*100) + "%",
              showCancelButton: true,
              cancelButtonText: 'Quay về chỉnh sửa',
              confirmButtonText: 'Tiếp tục gửi',
              // chỉ khi có ít nhất 1 thông tin (số điện thoại hoặc email) đúng thì mới hiển thị nút confirm gửi yêu cầu
              showConfirmButton: (checkDataOld.allowSubmit > 0),
              reverseButtons: true
            }).then((result) => {
              if (result.isConfirmed) {
                confirmUpdateUser(body, type);
              } else if (result.isDismissed) {
                setTimesCheckData(timesCheckData + 1)
              }
            });
          }
        }
      }
    };
    
    const confirmUpdateUser = async (body, type) => {
      let updateInfo = await apiUpdateAccount(body);
      if (updateInfo) {
        openModalConfirm(type);
      }
    };

    const resetValidate = async (type) => {
      validateForm();
      setErrors({});
    };

    const resetDataUpdate = async (type) => {
      setDataUpdate(originalDataUpdate);
    };


    const closeModalConfirm = () => {
      setOpenConfirm(false);
      document.getElementsByClassName("popup-overlay")[0].style.visibility = "visible";
    };

    // không còn sử dụng
    // const openModalSuccess = async () => {
      
    //   let body = {
    //     user: user,
    //     userInforCheck: dataCheck,
    //     userInforPending: dataUpdate,
    //   };
    //   let checkConfirmUpdate = await apiConfirmUpdate(body);
    //   setCheckConfirm(checkConfirmUpdate);
    //   setOpen(false);
    //   setOpenConfirm(false);
    //   setOpenResult(true);
    // };

    return (
      <div className="container">
        <motion.div
          // className="app"
          // initial={{ opacity: 0 }}
          // animate={{ opacity: 1 }}
          // transition={{ duration: 5 }}
          // className="app"
          // initial={{ x: "-100vw", opacity: 0 }}
          // animate={{ x: 0, opacity: 1 }}
          // exit={{ x: "100vw", opacity: 0 }}
          // transition={{ type: "spring", stiffness: 100, damping: 20 }}
          className="app"
          initial={{ x: "100vw", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: "-100vw", opacity: 0 }}
          transition={{ type: "spring", stiffness: 1000, damping: 200, duration: 10 }}
        >
          <Header isPageSearch={false} />

          <div className="content">
            <div className="profile">
              <span className="profile-titlte">THÔNG TIN CÁ NHÂN</span>
              <InfoUser />
              <span className="profile-titlte">THAY ĐỔI MỤC THÔNG TIN MỚI</span>
              <div className="action">
                <button className="action-btn" onClick={() => openModal("FULL_NAME")}>
                  <img src={iconUser} alt="icon-user" />
                  THAY ĐỔI HỌ VÀ TÊN
                </button>
                <button className="action-btn" onClick={() => openModal("PHONE_NUMBER")}>
                  <img src={iconPhone} alt="icon-phone" />
                  &nbsp; THAY ĐỔI SĐT
                </button>
              </div>
              <div className="action">
                <button className="action-btn" onClick={() => openModal("MAIL")}>
                  <img src={iconMail} alt="icon-mail" />
                  &nbsp; THAY ĐỔI GMAIL
                </button>
                <button className="action-btn" onClick={() => openModal("BANK")}>
                  <img src={iconBank} alt="icon-bank" />
                  &nbsp; ĐỔI STK NGÂN HÀNG
                </button>
              </div>
            </div>
            <Popup open={open} onClose={() => {setOpen(false); document.body.style.overflow = 'auto';}} position={"center"} closeOnDocumentClick={false} nested>
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }} id="modal-info" className="modal-info">
                <div className="modal-close">
                  <button className="icon-close" onClick={() => {setOpen(false); resetValidate(); resetDataUpdate()}} onAnimationEnd={() => setOpen(false)}>
                    <img src={iconClose} alt="icon-close" />
                  </button>
                </div>
                <div className="info-current">
                  <Collapsible open={true} trigger={["THÔNG TIN CÁ NHÂN", <img src={iconArrow} key="icon-arrow" alt="icon-arrow" />]}>
                    <InfoUser />
                  </Collapsible>
                </div>
                <div className="confirm-info-old">
                  <span className="modal-titlte">VUI LÒNG NHẬP LẠI THÔNG TIN ĐÃ LIÊN KẾT</span>
                  <div className="input-info">
                    <input type="text" name="name" value={dataCheck.name} onChange={inputDataCheck} placeholder="Họ Và Tên" />
                    {errors.name && <p className="error">{errors.name}</p>}
                    <input type="text" name="phone_number" value={dataCheck.phone_number} onChange={inputDataCheck} placeholder="Số Điện Thoại" />
                    {errors.phone_number && <p className="error">{errors.phone_number}</p>}
                    <input type="text" name="email" value={dataCheck.email} onChange={inputDataCheck} placeholder="Gmail" />
                    {errors.email && <p className="error">{errors.email}</p>}
                    <input type="text" name="bank_name" value={dataCheck.bank_name} onChange={inputDataCheck} placeholder="Ngân Hàng" />
                    {errors.bank_name && <p className="error">{errors.bank_name}</p>}
                    <input type="text" name="bank_number" value={dataCheck.bank_number} onChange={inputDataCheck} placeholder="Số Tài Khoản" />
                    {errors.bank_number && <p className="error">{errors.bank_number}</p>}
                  </div>
                </div>
                <div className="confirm-info-new">
                  <span className="modal-titlte">THAY ĐỔI THÔNG TIN MỚI</span>
                  {
                    {
                      "FULL_NAME": 
                      <div className="input-info">
                        <input type="text" name="name" onChange={inputDataUpdate} placeholder="Họ Tên Mới" />
                        {errors.nameUpdate && <p className="error">{errors.nameUpdate}</p>}
                      </div>,
                      "PHONE_NUMBER": 
                      <div className="input-info">
                        <input type="text" name="phone_number" onChange={inputDataUpdate}  placeholder="Số Điện Thoại Mới" />
                        {errors.phoneNumberUpdate && <p className="error">{errors.phoneNumberUpdate}</p>}
                      </div>,
                      "MAIL": 
                      <div className="input-info">
                        <input type="text" name="email" onChange={inputDataUpdate} placeholder="Email Mới" />
                        {errors.emailUpdate && <p className="error">{errors.emailUpdate}</p>}
                        </div>,
                      "BANK": 
                      <div className="input-info">
                        <input type="text" name="bank_name" onChange={inputDataUpdate} placeholder="Tên Ngân Hàng Mới" />
                        {errors.bankNameUpdate && <p className="error">{errors.bankNameUpdate}</p>}
                        <input type="text" name="bank_number" onChange={inputDataUpdate} placeholder="Số Tài Khoản Ngân Hàng Mới" />
                        {errors.bankNumberUpdate && <p className="error">{errors.bankNumberUpdate}</p>}
                        <input type="text" name="province" onChange={inputDataUpdate} placeholder="Chi Nhánh Ngân Hàng Mới" />
                        {errors.province && <p className="error">{errors.province}</p>}
                        </div>,
                    }[typeModal]
                  }
                </div>
                <div className="mode-verify">
                  <span className="modal-titlte">VUI LÒNG LỰA CHỌN HÌNH THỨC GỬI XÁC THỰC</span>
                  <div className="action action-verify">
                    { data.email &&
                    <button onClick={() => updateUser("MAIL")} className="action-verify-btn">
                      <img src={iconSendMail} alt="icon-bank" />
                      &nbsp; XÁC THỰC EMAIL
                    </button>
                    }
                    { data.phone_number &&
                    <button onClick={() => updateUser("PHONE_NUMBER")} className="action-verify-btn">
                      <img src={iconSendPhone} alt="icon-bank" />
                      &nbsp; XÁC THỰC SĐT
                    </button>
                    }
                  </div>
                </div>
              </motion.div>
            </Popup>
            <Popup open={openConfirm} closeOnDocumentClick={false} onClose={closeModalConfirm} position={"center"} nested>
              <motion.div
                initial={{ opacity: 0, y: 75 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                id="modal-confirm"
                className="modal-confirm"
              >
                <div className="modal-close">
                  <a className="icon-close" onClick={closeModalConfirm}>
                    <img src={iconClose} alt="icon-close" />
                  </a>
                </div>
                <div className="modal-confirm-content">
                  <img className="icon-warning" src={iconWarning} />
                  {isSendMail ? (
                    <p>
                      Vui lòng sử dụng gmail đã liên kết <span style={{ color: "green" }}>{data.email}</span>, gửi tin nhắn tới email 798BET{" "}
                      <span style={{ color: "red" }}>{ webSetting.email }</span>, với nội dung:
                    </p>
                  ) : (
                    <p>
                      Vui lòng sử dụng số điện thoại đã liên kết <span style={{ color: "green" }}>{data.phone_number}</span>, gửi tin nhắn tới số điện thoại 798BET{" "}
                      <span style={{ color: "red" }}>{ webSetting.phone_number }</span>, với nội dung:
                    </p>
                  )}
                  <DataConfirm user={user}
                  dataCheck = {dataCheck}
                  dataUpdate = {dataUpdate}
                  webSetting = {webSetting}/>
                  {/* <div style={{ marginTop: "15px" }}>
                    <p className="text-confirm-bottom">Vui lòng nhấn Xác nhận sau khi đã gửi thành công</p>
                    <button onClick={openModalSuccess} className="btn-send-mail">
                      XÁC NHẬN ĐÃ GỬI
                    </button>
                  </div> tạm thời không xử lý xác nhận đã gửi*/}
                  <div class="notification">
                    <p style={{ color: "#F7B922", padding: "3px 0px",margin: "0px"}}>Lưu ý:</p>
                    <p>- Sau khi nhận được { (isSendMail) ? 'email' : 'tin nhắn' }, hệ thống xét duyệt từ 2-5 phút.</p>
                    <p>- Quý khách vui lòng kiểm tra kết quả xử lý của hệ thống tại Hòm Thư.</p>
                    <p>- Liên hệ <a href={ webSetting.link_cskh || "https://iro6rszf.789bhotro.com/" }
                      target="_blank"
                      rel="noopener noreferrer">CSKH</a> nếu cần hỗ trợ thêm.</p>
                </div>
                </div>
              </motion.div>
            </Popup>
            <Popup open={openResult} onClose={() => setOpenResult(false)} position={"center"}>
              <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }} className="modal-success">
                <div className="modal-close">
                  <a className="icon-close" onClick={() => setOpenResult(false)}>
                    <img src={iconClose} alt="icon-close" />
                  </a>
                </div>
                {checkConfirm ? (
                  <div className="modal-result-content">
                    <img style={{ marginBottom: "20px" }} src={iconSuccess} alt="icon-success" />
                    <span>Chúng tôi đã nhận được yêu cầu của Quý khách, vui lòng chờ 2-5 phút và nhận kết quả phản hồi tại Hòm thư nội bộ</span>
                  </div>
                  ) : (
                  <div className="modal-result-content">
                    <img style={{ marginBottom: "20px" }} src={iconWarning} alt="icon-success" />
                    <span>Yêu cầu của bạn chưa được ghi nhận, vui lòng thử lại hoặc liên hệ chăm sóc khách hàng</span>
                  </div>
                )}
              </motion.div>
            </Popup>
          </div>
        </motion.div>
      </div>
    );
  }
}

export default Profile;
