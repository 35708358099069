import "../../src/assets/styles/Header.css";
import logo from "../imgs/logo.png";
import iconBack from "../icons/icon-back.png";
import { useNavigate } from "react-router-dom";
function Header({ isPageSearch }) {
  const navigate = useNavigate();
  const backToSearch = () => {
    navigate("/");
  };

  return (
    <div>
      <div className="status-bar"></div>
      <div className="header">
        <div className="header-logo">
          {!isPageSearch && (
            <img
              className="img-icon-back"
              style={{ cursor: "pointer" }}
              src={iconBack}
              alt="icon-back"
              id="btn-back"
              onClick={backToSearch}
            />
          )}
          <div className="main-logo">
            <img src={logo} alt="logo"></img>
          </div>
        </div>
        <p>HỖ TRỢ KHÁCH HÀNG</p>
      </div>
    </div>
  );
}

export default Header;
